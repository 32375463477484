var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { cn } from "../../utils/index.js";
const buttonVariants = cva("ring-offset-base-100 focus-visible:ring-ring relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50", {
    variants: {
        variant: {
            default: "bg-primary text-primary-foreground hover:bg-primary/90",
            destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
            outline: "border-input hover:bg-accent hover:text-accent-foreground border",
            secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
            ghost: "hover:bg-accent hover:text-accent-foreground",
            link: "text-primary underline-offset-4 hover:underline",
        },
        size: {
            default: "h-10 px-4 py-2",
            sm: "h-9 rounded-md px-3",
            lg: "h-11 rounded-md px-8",
            icon: "size-10",
        },
        fullWidth: {
            true: "w-full",
        },
    },
    defaultVariants: {
        variant: "default",
        size: "default",
    },
});
const Button = React.forwardRef((_a, ref) => {
    var { className, variant, size, fullWidth, loading, asChild = false, children } = _a, props = __rest(_a, ["className", "variant", "size", "fullWidth", "loading", "asChild", "children"]);
    const Comp = asChild ? Slot : "button";
    return (_jsxs(Comp, Object.assign({ className: cn(buttonVariants({
            variant,
            size,
            fullWidth,
            className,
        })), ref: ref }, props, { children: [_jsx("span", { className: cn("flex items-center leading-[1.25rem]"), style: { opacity: loading ? 0 : 100 }, children: children }), Boolean(loading) && (_jsxs("span", { className: "absolute left-1/2 top-1/2 block size-4 -translate-x-1/2 -translate-y-1/2", children: [_jsx("svg", { className: "animate-spin", viewBox: "0 0 20 20", style: { fill: "currentColor" }, children: _jsx("path", { d: "M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z" }) }), _jsx("span", { className: "sr-only", children: "Loading" })] }))] })));
});
Button.displayName = "Button";
export { Button, buttonVariants };
